import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function Index() {
  useEffect(() => {
    $('#countdown_dashboard').countDown({
      targetDate: {
        day: 7,
        month: 1,
        year: 2013,
        hour: 18,
        min: 30,
        sec: 0,
      },
    });
  });

  return (
    <Layout>
      <SEO title="" />
      <p>&nbsp;</p>
      <img alt="" width="660" height="660" src="/img/cover.jpg" />
      <p>&nbsp;</p>
      <div className="text-align-center">
        <div id="countdown_dashboard" className="inline center">
          <div className="dash weeks_dash">
            <div className="dash_title">weeks</div>
            <div className="digit">0</div>
            <div className="digit">6</div>
          </div>

          <div className="dash days_dash">
            <span className="dash_title">days</span>
            <div className="digit">0</div>
            <div className="digit">1</div>
          </div>

          <div className="dash hours_dash">
            <span className="dash_title">hours</span>
            <div className="digit">1</div>
            <div className="digit">8</div>
          </div>

          <div className="dash minutes_dash">
            <span className="dash_title">minutes</span>
            <div className="digit">3</div>
            <div className="digit">8</div>
          </div>

          <div className="dash seconds_dash">
            <span className="dash_title">seconds</span>
            <div className="digit">5</div>
            <div className="digit">3</div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
